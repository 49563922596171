<template>
  <div class="addScenery">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加景区</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="基本信息" name="1">
        <el-form ref="form" label-width="80px">
          <el-form-item label="景区名称">
            <el-input
              v-model="formList.sceneryName"
              placeholder="请输入景区名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属分类">
            <el-select v-model="selectValue" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-select
              v-show="selectValue"
              v-model="formList.cateId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options1"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="营业时间" class="col">
            <el-input
              v-model="formList.openTime"
              placeholder="请输入营业时间"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服电话" class="col">
            <el-input
              v-model="formList.tel"
              placeholder="请输入客服电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="标签">
            <el-input
              v-model="formList.label"
              placeholder="请输入景区便签"
            ></el-input>
          </el-form-item>
          <el-form-item label="推荐理由">
            <el-input
              v-model="formList.recommend"
              placeholder="请输入推荐理由"
            ></el-input>
          </el-form-item>

          <el-form-item label="所在街道" class="street">
            <el-select placeholder="请选择省" v-model.trim="provinceValue">
              <el-option
                v-for="item in provinceList"
                :key="item.name"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-select
              v-show="provinceValue"
              placeholder="请选择市"
              v-model.trim="cityValue"
            >
              <el-option
                v-for="item in cityList"
                :key="item.name"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-select
              v-show="cityValue"
              placeholder="请选择地区"
              v-model.trim="areaValue"
            >
              <el-option
                v-for="item in areaList"
                :key="item.name"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-select
              v-show="areaValue"
              placeholder="请选择街道"
              v-model="formList.streetCode"
              @change="formatDEfaultAddress"
            >
              <el-option
                v-for="item in streetList"
                :key="item.name"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地址" class="map">
            <my-map 
            @selectAddress="onAddressChange"
               @onMapClick="onMapClick"
                  :Address1="formList.address"></my-map>
          </el-form-item>
          <el-form-item label="经度" class="col">
            <el-input v-model.number="formList.lon"></el-input>
          </el-form-item>
          <el-form-item label="纬度" class="col">
            <el-input v-model.number="formList.lat"></el-input>
          </el-form-item>

          <el-form-item label="景区简介">
            <el-input
              type="textarea"
              :rows="6"
              v-model="formList.summary"
              placeholder=""
              style="width: 600px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="activeName = '2'">下一步</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="景区图片" name="2">
        <el-form ref="form" label-width="80px">
          <el-form-item label="展示图" class="showImg">
            <send-image
              type="one"
              @addImageStr="(e) => addImageStr(e, 1)"
            ></send-image>

            <span style="color: red; margin-left: 20px"
              >每张图片不能超过500K，建议尺寸：345*167px</span
            >
          </el-form-item>
          <el-form-item label="分享图" class="showImg">
            <send-image
              type="one"
              @addImageStr="(e) => addImageStr(e, 2)"
            ></send-image>

            <span style="color: red; margin-left: 20px"
              >每张图片不能超过500K，建议尺寸：250*150px</span
            >
          </el-form-item>
          <el-form-item label="轮播图" class="showImg">
            <send-image type="more" @addImageStrs="addImageStrs"></send-image>

            <span style="color: red; margin-left: 20px"
              >每张图片不能超过500K，建议尺寸：375*187px</span
            >
          </el-form-item>
           <el-form-item label="PC轮播图" >
              <send-image type="morePc" :maxLength="4" @addImageStrsPc="addImageStrsPc"></send-image>
              <span style="color: red; margin-left: 20px"
                >每张图片不能超过500K，建议尺寸：1960*680px</span
              >
            </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="activeName = '1'">上一步</el-button>
          <el-button type="primary" @click="activeName = '3'">下一步</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="详细说明" name="3">
        <el-form ref="form" label-width="80px">
          <el-form-item label="预定须知">
            <!-- <quill-editor
              class="richEdit"
              v-model="formList.bookNotice"
              :options="quillOption"
              ref="myTextEditor"
            >
            </quill-editor> -->
            <VueUeditorWrap v-model="formList.bookNotice" :config="myConfig"  ref="myTextEditor"></VueUeditorWrap>
          </el-form-item>
          <el-form-item label="景区详情">
            <!-- <quill-editor
              class="richEdit"
              v-model="formList.details"
              :options="quillOption"
              ref="myTextEditor"
            >
            </quill-editor> -->
            <VueUeditorWrap v-model="formList.details" :config="myConfig"  ref="myTextEditor"></VueUeditorWrap>
          </el-form-item>
          <el-form-item label="">
            <span style="color: red">建议尺寸750*1500px</span>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="activeName = '2'">上一步</el-button>
          <el-button type="primary" @click="onSave">保存</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { sceneryCate, selectAllByFatherId } from "../../api/sceneryCate";
import myMap from "../../components/map.vue";
import { sendFile } from "../../api/sendFile";
import { Quill } from "vue-quill-editor";

import quillConfig from "../../../quill-config";
import {
  getAllProvince,
  getCityByProvince,
  getAreaByCity,
  getStreetByArea,
} from "../../api/address";
import { addScenery } from "../../api/sceneryList";
import SendImage from "../../components/sendImage.vue";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: "addScenery",
  components: {
    myMap: myMap,
    SendImage,
    VueUeditorWrap
  },
  data() {
    return {
       myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      activeName: "1",
      options: [],
      options1: [],
      selectValue: null,
      provinceValue: "",
      cityValue: "",
      areaValue: "",
      streetValue: "",
      provinceList: [],
      cityList: [],
   
      areaList: [],
      streetList: [],
      imgList: [],
 
      formList: {
        sceneryName: "",
        cateId: null,
        openTime: "",
        tel: "",
        label: "",
        score: 4.9,
        streetCode: "",
        address: "",
        lon: '',
        lat: '',
        showImg: "",
        imgUrl: [],
        summary: "",
        bookNotice: "",
        details: "",
        recommend: "",
        shareImg: "",
        pcImg:""
      },
    };
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  watch: {
    provinceValue: async function () {
      const { data } = await getCityByProvince({
        provinceCode: this.provinceValue,
      });
      this.cityList = data.data;
      this.formatDEfaultAddress()
    },
    cityValue: async function () {
      const { data } = await getAreaByCity({
        cityCode: this.cityValue,
      });
      this.areaList = data.data;
       this.formatDEfaultAddress()
    },
    areaValue: async function () {
      const { data } = await getStreetByArea({
        areaCode: this.areaValue,
      });
      this.streetList = data.data;
       this.formatDEfaultAddress()
    },
    selectValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.selectValue,
      });
      this.options1 = data.data;
       this.formatDEfaultAddress()
    },
    imgList: function () {
      this.formList.imgUrl = this.imgList.join(",");
    },
  },
  created() {
    this.getAllProvinceList();
    this.getScCateList();
  },
  methods: {
    onMapClick({address,lat,lon}){
      this.formList.address = address
      this.formList.lat = lat
      this.formList.lon = lon

    },
    formatDEfaultAddress(){
      // 获取选择默认地址
       const pro = this.provinceList.filter(item=>{
       return item.code == this.provinceValue
      })
       const city= this.cityList.filter(item2=>{
       return item2.code == this.cityValue
      })
      const area = this.areaList.filter(item3=>{
       return item3.code == this.areaValue
      })
      const street = this.streetList.filter(item4=>{
       return item4.code == this.formList.streetCode
      })
      let addressArr = [...pro,...city,...area,...street]
      let defaultArr = []
     addressArr.forEach(item5=>{
      defaultArr.push(item5.name)
     })
     this.formList.address =  defaultArr.join('')
     console.log( defaultArr.join(''),'defaultAddress')
    },
    async onSave() {
  
      for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            type: "error",
            message: "请把数据填写完整",
          });
        }
      }
      this.formList.pcImg = this.formList.pcImg.join(",")
      const { data } = await addScenery({ ...this.formList, sceneryId: 0 });
      if (data.code == 0) {
        this.$router.go(-1);
        this.$message({
          type: "success",
          message: data.msg,
        });
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
    },
    async getScCateList() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      this.options = data.data;
    },
    async getAllProvinceList() {
      const { data } = await getAllProvince();
      this.provinceList = data.data;
    },
     onAddressChange({pname,cityname,adname,address,location}) {
        const { lat,lng} = location
      console.log(address, lat,lng);
      this.formList.address =  pname+cityname+adname+address;
      this.formList.lon = lng;
      this.formList.lat = lat;
    },
 
    addImageStr(image, type) {
      if (type === 1) {
        this.formList.showImg = image;
      } else if (type === 2) {
        this.formList.shareImg = image;
      }
    },
    addImageStrs(image) {
      this.imgList = image;
    },
    addImageStrsPc(image) {
      console.log("pc",image)
      this.formList.pcImg = image;
    },
    handleClick(tab, event) {},
  },
};
</script>
<style lang="less" scoped>
.addScenery {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .el-tabs {
    .btn {
      width: 400px;
      margin: 20px auto;
    }

    margin-top: 50px;

    .el-tabs__content {
      /deep/.el-textarea {
        height: 200px;
        width: 800px;

        .el-textarea__inner {
          height: 200px;
        }
      }
      #pane-2 {
        /deep/.el-form-item {
          .el-form-item__content {
          }
        }
      }

      #pane-3 {
        /deep/.el-textarea {
          height: 200px;
          width: 800px;

          .el-textarea__inner {
            height: 200px;
          }
        }

        /deep/.quill-editor {
          height: 400px;
          width: 800px;

          .ql-container {
            height: 300px;

            .ql-editor {
              height: 300px;
            }
          }
        }
      }

      .el-tab-pane {
        .el-form {
          .map {
            /deep/ .el-form-item__content {
              // height: 320px;

              .container {
                // height: 270px;
                width: 710px;

                .search-box {
                  #searchTip {
                    width: 710px !important;

                    .amap-ui-poi-picker-sugg {
                      .amap-ui-poi-picker-sugg-list {
                        width: 710px !important;
                      }
                    }
                  }
                }
              }
            }
          }

          /deep/ .col {
            display: inline-block;

            .el-form-item__content {
              width: 300px;

              .el-input {
                width: 305px;
              }
            }
          }

          .street {
            .el-form-item__content {
              .el-select {
                width: 175px;
              }
            }
          }

          .el-form-item__content {
            .el-select {
              width: 350px;
            }

            .el-input {
              width: 700px;
            }
          }
        }
      }
    }
  }
}
</style>
